import React, {useCallback, useState, useRef, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field, Form} from 'formik';
import {Modal, Typography} from "@mui/material";
import lodash from 'lodash'

import ModalCloseIcon from '../../icons/ModalCloseIcon';
import AddAssetProgressActiveIcon from '../../icons/AddAssetProgressActiveIcon';
import AddAssetProgressInActiveIcon from '../../icons/AddAssetProgressInActiveIcon';
import AddAssetProgressCompletedIcon from '../../icons/AddAssetProgressCompletedIcon';
import SimpleSelectInput from '../../commonComponents/SimpleSelectInput';
import DollarInputIcon from '../../icons/DollarInputIcon';
import SelectSponserIcon from '../../icons/SelectSponserIcon';
import SearchAbleSingleSelector from '../../SearchAbleSingleSelector';
import AddEntityIcon from '../../icons/AddEntityIcon';
import CircleClearIcon from '../../icons/CircleClearIcon';
import TrashIcon from '../../icons/TrashIcon';
import {getOrganizationIdOFLoggedUser} from '../../../util/commonFunctions';

import {getAssetEvents, createNewAssetEvent, updateEvent} from '../../../redux/slices/assets/assetSummarySlice';
import {getNewAccessToken} from '../../../redux/slices/auth/authSlice';
import {hideEditEventModal} from '../../../redux/slices/assets/assetSummarySlice';
import {setNewEventEntitiesMaxCount} from '../../../redux/slices/assets/assetSummarySlice';
import {getOrganizationEvents} from '../../../redux/slices/eventdatabase/eventDataBaseSlice';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import {round} from "lodash/math";
import IconToolTip from "../../IconToolTip";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function EditEventModal() {

    const dispatch = useDispatch();
    const entitiesListParentContainerRef = useRef(null);
    const newEntityContainerRef = useRef(null);

    const editAssetEventData = useSelector(state => state.assetSummary.editAssetEventData)
    const editEventModalShown = useSelector(state => state.assetSummary.editEventModalShown)
    const newEventEntitiesListMaxCount = useSelector(state => state.assetSummary.newEventEntitiesListMaxCount)
    const allEntities = useSelector(state => state.assetSummary.allEntities)
    const eventEntities = useSelector(state => state.assetSummary.eventEntities)
    const sharePrice = useSelector(state => state.assetSummary.sharePrice)
    const addEventSelectedAssetId = useSelector(state => state.assetSummary.addEventSelectedAssetId)
    const assetOptions = useSelector(state => state.assetSummary.assetOptions)
    const eventTypes = useSelector(state => state.assetSummary.eventTypes)
    const editEventScreenName = useSelector(state => state.assetSummary.editEventScreenName)
    const user = useSelector(state => state.auth.user)

    const [serverError, setServerError] = useState([])

    const closeEditEventModal = () => {
        setServerError([])
        dispatch(hideEditEventModal())
    }

    const formReadyToNext = (values) => {
        if (!values.asset || !values.date || values.type === '') {
            return false;
        } else {
            return true;
        }
    }

    const formReadyToSubmit = (values) => {
        if (values.type === 0 || values.type === 2 || values.type === 3) {
            if (!values.total_amount) {
                return false;
            }
        } else if (values.type === 1) {
            if (!values.share_price || !values.total_amount) {
                return false;
            } else {
                const hasMissingPercentage = values.entities.map((entity, index) => {
                    if (!values[`percentage_${entity.id}`]) {
                        if (values[`percentage_${entity.id}`] !== 0){
                            return false;
                        }
                    }
                });

                if (hasMissingPercentage.includes(false)) {
                    return false;
                }
            }
        }

        return true;
    }

    const getEventEntities = (values) => {
        const entityDataArray = [];

        values.entities.forEach(entity => {
            if (entitiesListParentContainerRef && entitiesListParentContainerRef.current) {
                const child = entitiesListParentContainerRef.current.children[entity.id];
                if (child) {
                    const rowComponent = child.querySelectorAll('.row-component');

                    if (rowComponent.length > 0) {

                        Array.from(rowComponent).map((inputElement) => {
                            const entityData = {};

                            const entity_name = inputElement.querySelector('input[name^="entity_name_"]');
                            const new_entity_name = inputElement.querySelectorAll('.select__single-value');

                            const amount = inputElement.querySelector('input[name^="amount_"]');
                            const percentage = inputElement.querySelector('input[name^="percentage_"]');

                            if (new_entity_name.length > 0) {
                                Array.from(new_entity_name).map((inputElement) => {
                                    entityData.entity = inputElement.textContent;
                                });
                            } else if (entity_name) {
                                entityData.entity = entity_name.value
                            }

                            entityData.invested = amount.value.replace(/,/g, '')
                            entityData.percentage = percentage.value.replace(/,/g, '')

                            entityDataArray.push(entityData);
                        });
                    }
                }
            }
        });

        return entityDataArray;
    }

    const submitAssetEventForm = (values, retry = 0) => {
        let invested_data = getEventEntities(values)

        if (values.type === 1 || values.type === 2) {
            const totalPercentage = invested_data.reduce((sum, item) => sum + parseFloat(item.percentage), 0);
            if (round(totalPercentage, 0) !== 100) {
                let errorArray = {}
                // errorArray["percentage"] = "Entities percentage should be 100%. Currently it's " + totalPercentage + "%"
                errorArray["percentage"] = "Entities percentage total should be 100."
                setServerError([errorArray])
                return ""
            } else {
                setServerError([])
            }
        }
        let params = {
            values: {
                asset: values.asset,
                date: values.date,
                type: lodash.find(eventTypes, function (event) {
                    return event.value === values.type
                }).label,
                notes: values.notes,
                total_amount: values.total_amount.replace(/,/g, ''),
                entity_data: invested_data,
                organization_id: getOrganizationIdOFLoggedUser(user),

            },
            organization: getOrganizationIdOFLoggedUser(user),
            asset_id: values.asset,
            event_id: values.event_id,
        }

        dispatch(updateEvent(params))
            .then(({payload}) => {
                if (payload && payload.status === 201) {
                    if (editEventScreenName === 'asset_summary') {
                        dispatch(getAssetEvents({
                            organization: getOrganizationIdOFLoggedUser(user),
                            asset_id: values.asset
                        }))
                            .then(({payload}) => {
                                if (payload && payload.status === 200) {
                                    closeEditEventModal()
                                }
                            })
                    } else if (editEventScreenName === 'event') {
                        dispatch(getOrganizationEvents({organization: getOrganizationIdOFLoggedUser(user)}))
                            .then(({payload}) => {
                                if (payload && payload.status === 200) {
                                    closeEditEventModal()
                                }
                            })
                    }
                } else if (payload && payload.response && payload.response.status === 400) {
                    const errorArray = {};
                    for (const key in payload.response.data) {
                        if (Object.hasOwnProperty.call(payload.response.data, key)) {
                            const value = payload.response.data[key]
                            errorArray[key] = value.join('. ')
                        }
                    }
                    setServerError([errorArray])
                } else if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                        .then((res) => {
                            if (res.payload && res.payload.data && res.payload.data.success) {
                                return submitAssetEventForm(values, retry + 1)
                            }
                        })
                }
            })
    }

    const handleKeyPress = useCallback((event) => {
        const charCode = event.charCode;
        if (charCode !== 0 && charCode !== 8 && charCode !== 46 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    }, []);

    const HeaderIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M14.0914 6.72222H20.0451C20.5173 6.72222 20.7534 6.72222 20.8914 6.82149C21.0119 6.9081 21.0903 7.04141 21.1075 7.18877C21.1272 7.35767 21.0126 7.56403 20.7833 7.97677L19.3624 10.5343C19.2793 10.684 19.2377 10.7589 19.2214 10.8381C19.207 10.9083 19.207 10.9806 19.2214 11.0508C19.2377 11.13 19.2793 11.2049 19.3624 11.3545L20.7833 13.9121C21.0126 14.3248 21.1272 14.5312 21.1075 14.7001C21.0903 14.8475 21.0119 14.9808 20.8914 15.0674C20.7534 15.1667 20.5173 15.1667 20.0451 15.1667H12.6136C12.0224 15.1667 11.7269 15.1667 11.5011 15.0516C11.3024 14.9504 11.141 14.7889 11.0398 14.5903C10.9247 14.3645 10.9247 14.0689 10.9247 13.4778V10.9444M7.23027 21.5L3.00805 4.61111M4.59143 10.9444H12.4025C12.9937 10.9444 13.2892 10.9444 13.515 10.8294C13.7137 10.7282 13.8751 10.5667 13.9763 10.3681C14.0914 10.1423 14.0914 9.84672 14.0914 9.25556V4.18889C14.0914 3.59772 14.0914 3.30214 13.9763 3.07634C13.8751 2.87773 13.7137 2.71625 13.515 2.61505C13.2892 2.5 12.9937 2.5 12.4025 2.5H4.64335C3.90602 2.5 3.53735 2.5 3.2852 2.65278C3.0642 2.78668 2.89999 2.99699 2.82369 3.24387C2.73663 3.52555 2.82605 3.88321 3.00489 4.59852L4.59143 10.9444Z"
                    stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    const DatePickerIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M17.5 8.33317H2.5M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M6.5 18.3332H13.5C14.9001 18.3332 15.6002 18.3332 16.135 18.0607C16.6054 17.821 16.9878 17.4386 17.2275 16.9681C17.5 16.4334 17.5 15.7333 17.5 14.3332V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332Z"
                    stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }

    const SingleDatePicker = (props) => {

        const handleDateRangeChange = (event, picker) => {
            if (picker.startDate) {
                props.setFieldValue('date', picker.startDate.format('YYYY-MM-DD'))
            }
        }

        return (
            <DateRangePicker
                initialSettings={{
                    singleDatePicker: true,
                    showDropdowns: false,
                    startDate: new Date(editAssetEventData.date),
                    maxDate: new Date(),
                    minYear: 1901
                }}
                onApply={handleDateRangeChange}
            >
                <input className="input-with-icon" name="date"/>
            </DateRangePicker>
        )
    }

    const getFormattedDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const addNewEntityButtonClicked = (setFieldValue, values) => {
        const updated_new_entities_list = [...values.entities, {
            id: newEventEntitiesListMaxCount,
            entity_name: '',
            amount: '',
            percentage: ''
        }];
        setFieldValue('entities', updated_new_entities_list);
        dispatch(setNewEventEntitiesMaxCount(newEventEntitiesListMaxCount + 1))
    }

    const removeComponentFromParent = (setFieldValue, values, id) => {
        const updatedChildren = values.entities.filter((entity) => entity.id !== id);
        setFieldValue('entities', updatedChildren);
    }

    const clicked = (setFieldValue, name, id) => {
        setFieldValue(name, true)
        if (name == 'new_entity_add_new_entity_clicked_' + id) {
            setFieldValue('entity_' + id, '')
            setFieldValue(`entity_name_${id}`, '')
        }
    }

    const swicthToSelectInput = (setFieldValue, name) => {
        setFieldValue(name, false)
    }

    const showEntities = (setFieldValue, values, entity) => {
        if (entity.hasOwnProperty('existed_entity')) {
            return (
                <div className='row-component' key={entity.id}>
                    <div className="row-input-with-icon-view existing-entity">
                        <div className="input-content">
                            <SelectSponserIcon isUserSelected={true}/>
                            <Field className="input-with-icon" disabled={true} placeholder="enter entity name..."
                                   onKeyPress={handleKeyPress} value={lodash.find(values.entities, function (option) {
                                return option.id === entity.id
                            }).entity_name}
                                   name={`entity_name_${entity.id}`}
                            />
                        </div>
                    </div>
                    {/*<div className={'percentage ' + `${values.type === 2 && 'percentage-disabled'}`}>*/}
                    <div className={'percentage ' + `${values.type === 2}`}>
                        <Field className="input-content" placeholder="0" onKeyPress={handleKeyPress}
                               onChange={(e) => {
                                   const newValue = e.target.value;
                                   let amount = round(values.total_amount * newValue / 100, 2)
                                   const formattedAmount = amount.toLocaleString(undefined, {
                                       minimumFractionDigits: 0,
                                       maximumFractionDigits: 2
                                   })
                                   setFieldValue(`amount_${entity.id}`, formattedAmount);
                                   setFieldValue(`percentage_${entity.id}`, newValue);

                                   let invested_data = getEventEntities(values)
                                   const totalPercentage = invested_data.reduce((sum, item) => sum + parseFloat(item.percentage), 0);
                                   if (round(totalPercentage,0) !== 100) {
                                       let errorArray = {}
                                       // errorArray["percentage"] = "Entities percentage should be 100%. Currently it's " + totalPercentage + "%"
                                       errorArray["percentage"] = "Entities percentage total should be 100."
                                       setServerError([errorArray])
                                       return ""
                                   } else {
                                       setServerError([])
                                   }
                               }}
                               name={`percentage_${entity.id}`}
                            // disabled={values.type === 2 && true }
                        />
                        <span>%</span>
                    </div>
                    <div className="row-input-with-icon-view invested-amount">
                        <div className="input-content">
                            <DollarInputIcon/>
                            <Field className="input-with-icon" disabled={true} placeholder="0.00"
                                   value={(values.total_amount.replace(/,/g, '') * values['percentage_' + entity.id] / 100).toLocaleString(undefined, {
                                       minimumFractionDigits: 0,
                                       maximumFractionDigits: 2
                                   })} onKeyPress={handleKeyPress} name={`amount_${entity.id}`}/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='row-component new-entity-row-component' key={entity.id}>
                    {values[`new_entity_add_new_entity_clicked_${entity.id}`] ?
                        <div className="row-input-with-icon-view new-entity">
                            <div className="input-content">
                                <SelectSponserIcon isUserSelected={true}/>
                                <Field className="input-with-icon" placeholder="enter entity name..."
                                       name={`entity_name_${entity.id}`}/>
                                <CircleClearIcon
                                    callMethod={() => swicthToSelectInput(setFieldValue, `new_entity_add_new_entity_clicked_${entity.id}`)}/>
                            </div>
                        </div>
                        :
                        <div className="row-input-with-icon-view all-new-entity">
                            <div className="input-content">
                                <Field placeholder="Select entity"
                                       clicked={() => clicked(setFieldValue, `new_entity_add_new_entity_clicked_${entity.id}`, entity.id)}
                                       existingEntity={false} parentContainerRef={newEntityContainerRef}
                                       className="entity-input" options={allEntities} showAddButtonInMenu={true}
                                       showMenuItemsIcon={true} showSelectedControlIcon={true} inputName={'asset-modal'}
                                       component={SearchAbleSingleSelector} isSearchAble={true}
                                       name={`entity_name_${entity.id}`}/>
                            </div>
                        </div>
                    }
                    <div className={'percentage ' + `${values.type === 2 && 'percentage-disabled'}`}>
                        <Field className="input-content" placeholder="0" onKeyPress={handleKeyPress}
                               name={`percentage_${entity.id}`}
                               onChange={(e) => {
                                   const newValue = e.target.value;
                                   let amount = round(values.total_amount * newValue / 100, 2)
                                   const formattedAmount = amount.toLocaleString(undefined, {
                                       minimumFractionDigits: 0,
                                       maximumFractionDigits: 2,
                                   });
                                   setFieldValue(`amount_${entity.id}`, formattedAmount);
                                   setFieldValue(`percentage_${entity.id}`, newValue);
                               }}
                               disabled={values.type === 2 && true}
                        />
                        <span>%</span>
                    </div>
                    <div className="row-input-with-icon-view invested-amount">
                        <div className="input-content">
                            <DollarInputIcon/>
                            <Field className="input-with-icon" disabled={true} placeholder="0.00"
                                   onKeyPress={handleKeyPress}
                                   value={(values.total_amount.replace(/,/g, '') * values['percentage_' + entity.id] / 100).toLocaleString(undefined, {
                                       minimumFractionDigits: 0,
                                       maximumFractionDigits: 2
                                   })} name={`amount_${entity.id}`}/>
                        </div>
                    </div>
                    <button type="button" onClick={() => removeComponentFromParent(setFieldValue, values, entity.id)}
                            className="remove-entity-button"><TrashIcon/></button>
                </div>
            )
        }
    }

    const goNext = (setFieldValue, values) => {
        setFieldValue('progress', 2)
        if (values.type === 2) {
            setFieldValue('entities', eventEntities)
        }
    }

    return (
        <Modal
            open={editEventModalShown}
            onClose={closeEditEventModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="asset-summary-add-event-modal-view"
        >
            <div className="add-asset-event-modal">
                <div className="add-asset-event-modal-header">
                    <div className="add-asset-event-modal-header-content">
                        <div className="add-asset-event-modal-header-icons-view">
                            <div className="add-asset-event-modal-header-feature-icon">
                                <HeaderIcon/>
                            </div>
                            <div className="add-asset-event-modal-header-close-button">
                                <ModalCloseIcon closeModal={closeEditEventModal}/>
                            </div>
                        </div>
                        <div className="add-asset-event-modal-header-text">
                            <Typography className="add-asset-event-modal-header-title-text">Update event</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-asset-event-modal-content">
                    <Formik
                        initialValues={{
                            progress: 1,
                            event_id: editAssetEventData.id,
                            asset: addEventSelectedAssetId && assetOptions && assetOptions.length > 0 && lodash.find(assetOptions, function (asset) {
                                return asset.value === addEventSelectedAssetId
                            }).value,
                            date: editAssetEventData && editAssetEventData.date,
                            type: eventTypes && eventTypes.length > 0 && editAssetEventData.hasOwnProperty('id') && lodash.find(eventTypes, function (event) {
                                return event.label === editAssetEventData.type
                            }).value,
                            notes: editAssetEventData && editAssetEventData.notes,
                            total_amount: editAssetEventData && editAssetEventData.total_amount && editAssetEventData.total_amount.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2
                            }),
                            share_price: sharePrice,
                            entities: eventEntities,
                            ...eventEntities.reduce((acc, entity, index) => {
                                acc[`percentage_${index}`] = entity.percentage;
                                return acc;
                            }, {}),
                        }}
                        onSubmit={(values) => {
                            submitAssetEventForm(values)
                        }}
                    >
                        {({errors, values, handleChange, handleSubmit, setFieldValue}) => {
                            return (

                                <>
                                    <div className="progress-step">
                                        <div className="progress-step-content">
                                            <div className='asset-details'>
                                                <div className='icon-view'>{values.progress === 2 ?
                                                    <AddAssetProgressCompletedIcon/> :
                                                    <AddAssetProgressActiveIcon/>}</div>
                                                <div className='content'>
                                                    <Typography className="content-title-text">Event
                                                        Details</Typography>
                                                    <Typography className="content-info-text">Provide details about the
                                                        event</Typography>
                                                </div>
                                            </div>
                                            <div className='line-view'>
                                                <div className={'line ' + `${values.progress === 2 && 'active-line'}`}>
                                                </div>
                                            </div>
                                            <div
                                                className={'capital-invested ' + `${values.progress === 2 && 'capital-invested-active'}`}>
                                                <div className='icon-view'><AddAssetProgressInActiveIcon/></div>
                                                <div className='content'>
                                                    <Typography className="content-title-text">Amount</Typography>
                                                    <Typography className="content-info-text">Enter amount
                                                        invested</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        <Form
                                            className={"add-asset-event-form " + `${values.progress === 2 && 'add-capital-asset-form'}`}>
                                            {values.progress === 1 &&
                                                <>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Asset</Typography>
                                                            <Field placeholder="Select user role"
                                                                   className="selector-input asset-name"
                                                                   isDisable={true} component={SimpleSelectInput}
                                                                   isFormik={true}
                                                                   selectedValue={lodash.find(assetOptions, function (asset) {
                                                                       return asset.value === addEventSelectedAssetId
                                                                   })} options={assetOptions} name="asset"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Date</Typography>
                                                            <div className="row-input-with-icon-view">
                                                                <div className="input-content">
                                                                    <DatePickerIcon/>
                                                                    <Field className="input-with-icon"
                                                                           setFieldValue={setFieldValue}
                                                                           component={SingleDatePicker} name="date"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Event Type</Typography>
                                                            <Field placeholder="Select user role"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput} isFormik={true}
                                                                   selectedValue={lodash.find(eventTypes, function (option) {
                                                                       return option.value === values.type
                                                                   })} options={eventTypes} name="type"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Note</Typography>
                                                            <div className="row-input-with-icon-view">
                                                                <div className="input-content">
                                                                    <Field placeholder="Input your notes in here..."
                                                                           as="textarea" rows={5}
                                                                           className="input-with-icon" name="notes"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="add-asset-event-modal-footer">
                                                        <div className="add-asset-event-modal-footer-content">
                                                            <div className="add-asset-event-modal-footer-buttons">
                                                                <button type="button" onClick={closeEditEventModal}
                                                                        className="cancel-asset-button">Cancel
                                                                </button>
                                                                <button type="button"
                                                                        onClick={formReadyToNext(values) ? () => goNext(setFieldValue, values) : null}
                                                                        className={"disable-next-asset-button " + `${formReadyToNext(values) ? 'enable-next-asset-button' : ''}`}>Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {values.progress === 2 &&
                                                <>
                                                    {values.type === 1 &&
                                                        <div className="input-field-view">
                                                            <div className="text-input-with-label">
                                                                <div className="tracking_price_text">
                                                                    <Typography className="input-label">Tracking
                                                                        Price</Typography>
                                                                    <div>
                                                                        <Tooltip
                                                                            title="Internal tracking price, not actual investment share price">
                                                                            <InfoOutlinedIcon/>
                                                                        </Tooltip>
                                                                    </div>

                                                                </div>
                                                                <div className="row-input-with-icon-view disable-input">
                                                                    <div className="input-content">
                                                                        <DollarInputIcon/>
                                                                        <Field disabled={true}
                                                                               className="input-with-icon"
                                                                               placeholder="0.00"
                                                                               onKeyPress={handleKeyPress}
                                                                               name="share_price"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="input-field-view">
                                                        <div className="text-input-with-label">
                                                            <Typography className="input-label">Total
                                                                Amount</Typography>
                                                            <div className="row-input-with-icon-view">
                                                                <div className="input-content">
                                                                    <DollarInputIcon/>
                                                                    <Field className="input-with-icon"
                                                                           placeholder="0.00"
                                                                           onKeyPress={handleKeyPress}
                                                                           name="total_amount"
                                                                           onChange={(e) => {
                                                                               const newValue = e.target.value;

                                                                               let rawValue = newValue.replace(/,/g, '');
                                                                               const parsedValue = parseFloat(rawValue);
                                                                               if (!isNaN(parsedValue)) {
                                                                                   const formattedValue = parsedValue.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 0,
                                                                                       maximumFractionDigits: 2,
                                                                                   });

                                                                                   setFieldValue('total_amount', formattedValue);
                                                                               } else {
                                                                                   setFieldValue('total_amount', '');
                                                                               }

                                                                               values.entities.map((entity, index) => {
                                                                                   let amount = round(newValue.replace(/,/g, '') * values[`percentage_${entity.id}`] / 100, 2)
                                                                                   const formattedAmount = amount.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 0,
                                                                                       maximumFractionDigits: 2,
                                                                                   });
                                                                                   setFieldValue(`amount_${entity.id}`, formattedAmount);
                                                                               });
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(values.type === 1 || values.type === 2) && values.entities.length > 0 &&
                                                        <div ref={entitiesListParentContainerRef}
                                                             className="input-field-view entities-parent-container">
                                                            <div className="text-input-with-label entities-container">
                                                                <Typography className="input-label">Entity</Typography>
                                                                {values.entities.length > 0 && values.entities.map((item, index) => (
                                                                    showEntities(setFieldValue, values, item)
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                    {values.type === 1 &&
                                                        <button type="button" className='add-entity-button-view'
                                                                onClick={() => addNewEntityButtonClicked(setFieldValue, values)}>
                                                            <AddEntityIcon/>
                                                            <Typography className="add-entity-label">Add
                                                                Entity</Typography>
                                                        </button>
                                                    }
                                                    <div className="add-asset-event-modal-footer">
                                                        <div className="add-asset-event-modal-footer-content">
                                                            {serverError && serverError.map((error, index) => (
                                                                error[lodash.keys(error)[0]] && <div
                                                                    className="error">{error[lodash.keys(error)[0]]}</div>
                                                            ))}
                                                            <div className="add-asset-event-modal-footer-buttons">
                                                                <button type="button"
                                                                        onClick={() => setFieldValue('progress', 1)}
                                                                        className="back-asset-button">Back
                                                                </button>
                                                                <button type="submit"
                                                                        className={"disable-add-asset-event-button " + `${formReadyToSubmit(values) ? 'enable-add-asset-event-button' : ''}`}>Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                        </Form>
                                    </>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default EditEventModal