import React, {useCallback, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field, Form} from 'formik';
import {Modal, Typography} from "@mui/material";
import lodash from 'lodash'

import AddAssetModalHeaderAssetIcon from '../../icons/AddAssetModalHeaderUserIcon';
import ModalCloseIcon from '../../icons/ModalCloseIcon';
import AddAssetProgressActiveIcon from '../../icons/AddAssetProgressActiveIcon';
import AddAssetProgressInActiveIcon from '../../icons/AddAssetProgressInActiveIcon';
import AddAssetProgressCompletedIcon from '../../icons/AddAssetProgressCompletedIcon';
import SimpleSelectInput from '../../commonComponents/SimpleSelectInput';
import DollarInputIcon from '../../icons/DollarInputIcon';
import AddEntityIcon from '../../icons/AddEntityIcon';
import SearchAbleSingleSelector from '../../SearchAbleSingleSelector';
import TrashIcon from '../../icons/TrashIcon';
import CircleClearIcon from '../../icons/CircleClearIcon';
import {getOrganizationIdOFLoggedUser} from '../../../util/commonFunctions';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {
    hideEditAssetModal,
    createNewAsset,
    getAssetSummary,
    setNewEntitiesMaxCount,
    updateAssetDetails, getAssetDetails
} from '../../../redux/slices/assets/assetSummarySlice';
import {getNewAccessToken} from '../../../redux/slices/auth/authSlice';
import SimpleDatePicker from "../../commonComponents/SimpleDatePicker";

function EditAssetModal() {

    const dispatch = useDispatch();

    const sponserContainerRef = useRef(null);
    const entityContainerRef = useRef(null);
    const newEntityContainerRef = useRef(null);
    const newEntitiesListParentContainerRef = useRef(null);

    const assetsWithDetails = useSelector(state => state.assetSummary.assetsWithDetails)

    const edit_asset_id = useSelector(state => state.assetSummary.edit_asset_id)
    const edit_asset_sponsor_list = useSelector(state => state.assetSummary.edit_asset_sponsor_list)
    const edit_asset_asset_class_list = useSelector(state => state.assetSummary.edit_asset_asset_class_list)
    const edit_asset_sub_asset_class = useSelector(state => state.assetSummary.edit_asset_sub_asset_class)
    const edit_asset_sector = useSelector(state => state.assetSummary.edit_asset_sector)
    const edit_asset_industry_group = useSelector(state => state.assetSummary.edit_asset_industry_group)
    const edit_asset_investment_type = useSelector(state => state.assetSummary.edit_asset_investment_type)
    const edit_asset_investment_position = useSelector(state => state.assetSummary.edit_asset_investment_position)
    const edit_asset_region = useSelector(state => state.assetSummary.edit_asset_region)
    const edit_asset_state = useSelector(state => state.assetSummary.edit_asset_state)
    const edit_asset_entity = useSelector(state => state.assetSummary.edit_asset_entity)


    const newEntitiesListMaxCount = useSelector(state => state.assetSummary.newEntitiesListMaxCount)
    const editAssetModalShown = useSelector(state => state.assetSummary.editAssetModalShown)

    const sponsor_list = useSelector(state => state.assetSummary.sponsor_list)
    const asset_class_list = useSelector(state => state.assetSummary.asset_class_list)
    const sub_asset_class = useSelector(state => state.assetSummary.sub_asset_class)
    const sector = useSelector(state => state.assetSummary.sector)
    const industry_group = useSelector(state => state.assetSummary.industry_group)
    const investment_type = useSelector(state => state.assetSummary.investment_type)
    const investment_position = useSelector(state => state.assetSummary.investment_position)
    const region = useSelector(state => state.assetSummary.region)
    const state = useSelector(state => state.assetSummary.state)
    const entity = useSelector(state => state.assetSummary.entity)
    const user = useSelector(state => state.auth.user)

    const [serverError, setServerError] = useState([])

    const closeEditAssetModal = () => {
        setServerError([])
        dispatch(setNewEntitiesMaxCount(0))
        dispatch(hideEditAssetModal())
    }

    const getAssetEntities = (values) => {
        values.new_entities_list.forEach(entity => {
            if (newEntitiesListParentContainerRef && newEntitiesListParentContainerRef.current) {
                const child = newEntitiesListParentContainerRef.current.children[entity.id];
                if (child) {
                    const investedInputs = child.querySelectorAll('input[name^="invested_"]');
                    const entityInputs = child.querySelectorAll('input[name^="entity_"]');
                    const entitySelectorInputs = child.querySelectorAll('.select__single-value');

                    if (entityInputs.length > 0) {
                        Array.from(entityInputs).map((inputElement) => {
                            entity.entity = inputElement.value;
                        });
                    } else if (entitySelectorInputs.length > 0) {
                        Array.from(entitySelectorInputs).map((inputElement) => {
                            entity.entity = inputElement.textContent;
                        });
                    }

                    Array.from(investedInputs).map((inputElement) => {
                        entity.invested = inputElement.value.replace(/,/g, '');
                    });
                }
            }
        });

        let entity_data = [...values.new_entities_list];
        entity_data = entity_data.map((entity) => {
            const {id, ...rest} = entity;
            return rest;
        });

        entity_data.unshift({entity: values.entity, invested: values.invested.replace(/,/g, '')});

        return entity_data;
    }

    const prepareAssetFormParams = (values) => {
        var sponsor_value = 0
        if (Number.isInteger(values.sponsor)) {
            sponsor_value = values.sponsor
        } else {
            sponsor_value = lodash.find(edit_asset_sponsor_list, function (sponsor) {
                return sponsor.label === values.sponsor
            }).value
        }


        console.log(values)


        let params = {
            id: edit_asset_id,
            name: values.name,
            investment: values.investment_and_non_investment === 'investment',
            sponsor: sponsor_value,
            // sponsor: lodash.find(edit_asset_sponsor_list, function (sponsor) { return sponsor.label === values.sponsor }).value,

            asset_class: lodash.find(edit_asset_asset_class_list, function (asset_class) {
                return asset_class.value === values.asset_class
            }).label,
            sub_class: lodash.find(edit_asset_sub_asset_class, function (sub_asset) {
                return sub_asset.value === values.sub_asset_class
            }).label,
            sector: lodash.find(edit_asset_sector, function (option) {
                return option.value === values.sector
            }).label,
            industry_group: lodash.find(edit_asset_industry_group, function (option) {
                return option.value === values.industry_group;
            }).label,
            investment_type: lodash.find(edit_asset_investment_type, function (option) {
                return option.value === values.investment_type;
            }).label,
            investment_position: lodash.find(edit_asset_investment_position, function (option) {
                return option.value === values.investment_position;
            }).label,
            region: lodash.find(edit_asset_region, function (option) {
                return option.value === values.region;
            }).label,
            state: lodash.find(edit_asset_state, function (option) {
                return option.value === values.state;
            }).label,
            date: values.date,
            entry_date: values.entry_date,
            exit_date: values.exit_date,

            // entry_date:
            // commitment: values.commitment.replace(/,/g, ''),
            // entity_data: getAssetEntities(values),
            organization: getOrganizationIdOFLoggedUser(user),
        }


        // lodash.find(edit_asset_sub_asset_class, function (sponsor) { return sponsor.label === assetsWithDetails.sub_asset_class; }).value,

        return params;
    }

    const submitAssetForm = (values, retry = 0) => {
        let params = prepareAssetFormParams(values)


        console.log(params)


        dispatch(updateAssetDetails(params))
            .then(({payload}) => {
                if (payload && payload.status === 200) {

                    // dispatch(getAssetSummary({ organization: getOrganizationIdOFLoggedUser(user) }))
                    dispatch(
                        getAssetDetails(
                            {
                                organization: getOrganizationIdOFLoggedUser(user),
                                asset_id: edit_asset_id
                            }
                        )
                    )
                        .then(({payload}) => {
                            if (payload && payload.status === 200) {
                                console.log("done")
                                closeEditAssetModal()
                            }
                        })
                } else if (payload && payload.response && payload.response.status === 400) {
                    const errorArray = {};
                    for (const key in payload.response.data) {
                        if (Object.hasOwnProperty.call(payload.response.data, key)) {
                            const value = payload.response.data[key]
                            errorArray[key] = value.join('. ')
                        }
                    }
                    setServerError([errorArray])
                } else if (payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
                    dispatch(getNewAccessToken())
                        .then((res) => {
                            if (res.payload && res.payload.data && res.payload.data.success) {
                                return submitAssetForm(values, retry + 1)
                            }
                        })
                }
            })
    }

    const getFormattedDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const investmentAndNonInvestmentHandleChange = (event, setFieldValue) => {
        setFieldValue('investment_and_non_investment', event.target.value)
        if (event.target.value == 'non-investment') {
            setFieldValue('asset_class', lodash.find(edit_asset_asset_class_list, function (asset_class) {
                return asset_class.label === 'Cash'
            }).value)
            // setFieldValue('sub_asset_class', lodash.find(sub_asset_class, function (sub_asset) { return sub_asset.label === 'Cash' }).value)
            // setFieldValue('sector', lodash.find(sector, function (single_sector) { return single_sector.label === 'Cash' }).value)
            // setFieldValue('industry_group', lodash.find(industry_group, function (option) { return option.label === 'Cash' }).value)
            // setFieldValue('investment_type', lodash.find(investment_type, function (option) { return option.label === 'Cash' }).value)
            // setFieldValue('investment_position', lodash.find(investment_position, function (option) { return option.label === 'Cash' }).value)
        } else if (event.target.value == 'investment') {
            // setFieldValue('asset_class', '')
            // setFieldValue('sub_asset_class', '')
            // setFieldValue('sector', '')
            // setFieldValue('industry_group', '')
            // setFieldValue('investment_type', '')
            // setFieldValue('investment_position', '')
        }

    }

    const formReadyToSubmit = (values) => {
        if (!values.sponsor || !values.asset_class || !values.sub_asset_class) {
            return false;
        }

        return true;
    }

    return (
        <Modal
            open={editAssetModalShown}
            onClose={closeEditAssetModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="add-asset-modal-view"
        >
            <div className="add-asset-modal">
                <div className="add-asset-modal-header">
                    <div className="add-asset-modal-header-content">
                        <div className="add-asset-modal-header-icons-view">
                            <div className="add-asset-modal-header-feature-icon">
                                <AddAssetModalHeaderAssetIcon/>
                            </div>
                            <div className="add-asset-modal-header-close-button">
                                <ModalCloseIcon closeModal={closeEditAssetModal}/>
                            </div>
                        </div>
                        <div className="add-asset-modal-header-text">
                            <Typography className="add-asset-modal-header-title-text">Edit asset</Typography>
                        </div>
                    </div>
                </div>
                <div className="add-asset-modal-content">
                    <Formik

                        initialValues={{
                            name: '',
                            sponsor: edit_asset_sponsor_list && assetsWithDetails && assetsWithDetails.sponsor && lodash.find(edit_asset_sponsor_list, function (sponsor) {
                                return sponsor.label === assetsWithDetails.sponsor;
                            }).value,
                            // asset_class: edit_asset_asset_class_list && assetsWithDetails && assetsWithDetails.asset_class && lodash.find(edit_asset_asset_class_list, function (sponsor) { return sponsor.label === assetsWithDetails.asset_class; }).value,

                            asset_class: edit_asset_asset_class_list && assetsWithDetails && assetsWithDetails.asset_class
                                ? (lodash.find(edit_asset_asset_class_list, function (sponsor) {
                                return sponsor.label === assetsWithDetails.asset_class;
                            }) || {}).value || null
                                : null,


                            // sub_asset_class: edit_asset_sub_asset_class && assetsWithDetails && assetsWithDetails.sub_asset_class && lodash.find(edit_asset_sub_asset_class, function (sponsor) { return sponsor.label === assetsWithDetails.sub_asset_class; }).value,
                            // sector: edit_asset_sector && assetsWithDetails && assetsWithDetails.sector && lodash.find(edit_asset_sector, function (sponsor) { return sponsor.label === assetsWithDetails.sector; }).value,
                            // industry_group: edit_asset_industry_group && assetsWithDetails && assetsWithDetails.industry_group && lodash.find(edit_asset_industry_group, function (sponsor) { return sponsor.label === assetsWithDetails.industry_group; }).value,
                            // investment_type: edit_asset_investment_type && assetsWithDetails && assetsWithDetails.investment_type && lodash.find(edit_asset_investment_type, function (sponsor) { return sponsor.label === assetsWithDetails.investment_type; }).value,
                            // investment_position: edit_asset_investment_position && assetsWithDetails && assetsWithDetails.investment_position && lodash.find(edit_asset_investment_position, function (sponsor) { return sponsor.label === assetsWithDetails.investment_position; }).value,
                            // region: edit_asset_region && assetsWithDetails && assetsWithDetails.region && lodash.find(edit_asset_region, function (sponsor) { return sponsor.label === assetsWithDetails.region; }).value,
                            // state: edit_asset_state && assetsWithDetails && assetsWithDetails.state && lodash.find(edit_asset_state, function (sponsor) { return sponsor.label === assetsWithDetails.state; }).value,

                            sub_asset_class: edit_asset_sub_asset_class && assetsWithDetails && assetsWithDetails.sub_asset_class
                                ? (lodash.find(edit_asset_sub_asset_class, function (sponsor) {
                                return sponsor.label === assetsWithDetails.sub_asset_class;
                            }) || {}).value || null
                                : null,

                            sector: edit_asset_sector && assetsWithDetails && assetsWithDetails.sector
                                ? (lodash.find(edit_asset_sector, function (sponsor) {
                                return sponsor.label === assetsWithDetails.sector;
                            }) || {}).value || null
                                : null,

                            industry_group: edit_asset_industry_group && assetsWithDetails && assetsWithDetails.industry_group
                                ? (lodash.find(edit_asset_industry_group, function (sponsor) {
                                return sponsor.label === assetsWithDetails.industry_group;
                            }) || {}).value || null
                                : null,

                            investment_type: edit_asset_investment_type && assetsWithDetails && assetsWithDetails.investment_type
                                ? (lodash.find(edit_asset_investment_type, function (sponsor) {
                                return sponsor.label === assetsWithDetails.investment_type;
                            }) || {}).value || null
                                : null,

                            investment_position: edit_asset_investment_position && assetsWithDetails && assetsWithDetails.investment_position
                                ? (lodash.find(edit_asset_investment_position, function (sponsor) {
                                return sponsor.label === assetsWithDetails.investment_position;
                            }) || {}).value || null
                                : null,

                            region: edit_asset_region && assetsWithDetails && assetsWithDetails.region
                                ? (lodash.find(edit_asset_region, function (sponsor) {
                                return sponsor.label === assetsWithDetails.region;
                            }) || {}).value || null
                                : null,

                            state: edit_asset_state && assetsWithDetails && assetsWithDetails.state
                                ? (lodash.find(edit_asset_state, function (sponsor) {
                                return sponsor.label === assetsWithDetails.state;
                            }) || {}).value || null
                                : null,


                            date: getFormattedDate(),
                            entry_date: assetsWithDetails.entry_date,
                            exit_date: assetsWithDetails.exit_date,
                            commitment: '',
                            entity: '',
                            invested: '',
                            investment_and_non_investment: assetsWithDetails && assetsWithDetails.investment ? 'investment' : 'non-investment',
                            new_sponser_add_clicked: false,
                            new_entities_and_invested_id_state: false,
                            existing_entity_add_new_entity_clicked: false,
                            new_entity_add_new_entity_clicked: false,
                            new_entities_list: [],
                        }}
                        onSubmit={(values) => {
                            submitAssetForm(values)
                        }}
                    >
                        {({errors, values, handleChange, handleSubmit, setFieldValue}) => {
                            return (
                                <>
                                    <>
                                        <Form className={"add-asset-form "}>
                                            <>
                                                <div className="input-field-view">
                                                    <FormControl>
                                                        {/*<RadioGroup*/}
                                                        {/*    row*/}
                                                        {/*    aria-labelledby="demo-row-radio-buttons-group-label"*/}
                                                        {/*    defaultValue="investment"*/}
                                                        {/*    name="investment_and_non_investment"*/}
                                                        {/*    onChange={ (event) => investmentAndNonInvestmentHandleChange(event, setFieldValue)}*/}
                                                        {/*>*/}
                                                        {/*    <FormControlLabel value="investment" control={<Radio*/}
                                                        {/*        checked={ values.investment_and_non_investment == 'investment' ? true : false }*/}
                                                        {/*        sx={{*/}
                                                        {/*            color: '#344054',*/}
                                                        {/*            '&.Mui-checked': {*/}
                                                        {/*                color: '#344054',*/}
                                                        {/*            },*/}
                                                        {/*            }}*/}
                                                        {/*            />} */}
                                                        {/*        label="Investment"*/}
                                                        {/*    />*/}
                                                        {/*    <FormControlLabel value="non-investment" control={<Radio*/}
                                                        {/*        checked={ values.investment_and_non_investment == 'non-investment' ? true : false }*/}
                                                        {/*        sx={{*/}
                                                        {/*            color: '#344054',*/}
                                                        {/*            '&.Mui-checked': {*/}
                                                        {/*                color: '#344054',*/}
                                                        {/*            },*/}
                                                        {/*            }}*/}
                                                        {/*        />}*/}
                                                        {/*    label="Non-investment" />*/}
                                                        {/*</RadioGroup>*/}
                                                    </FormControl>
                                                </div>
                                                <div className="input-field-view">
                                                    <div className="input-with-label">
                                                        <Typography className="input-label">Sponsor</Typography>
                                                        <div className="row-input-with-icon-view">
                                                            <div ref={entityContainerRef} className="input-content">
                                                                <Field placeholder="Select sponsor"
                                                                       addCustomSponser={false}
                                                                       className="sponsor-input"
                                                                       showAddButtonInMenu={false}
                                                                       showMenuItemsIcon={true}
                                                                       showSelectedControlIcon={true}
                                                                       inputName={'asset-modal'}
                                                                       component={SearchAbleSingleSelector}
                                                                       isSearchAble={false}
                                                                       selectedValue={edit_asset_sponsor_list && values.sponsor && lodash.find(edit_asset_sponsor_list, function (sponsor) {
                                                                           return sponsor.value === values.sponsor;
                                                                       })} options={edit_asset_sponsor_list}
                                                                       name="sponsor"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-row'>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Asset Class</Typography>
                                                            <Field placeholder="Select class" className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_asset_class_list && values.asset_class && lodash.find(edit_asset_asset_class_list, function (asset_class) {
                                                                       return asset_class.value === values.asset_class;
                                                                   })} options={edit_asset_asset_class_list}
                                                                   name="asset_class"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Sub-Asset
                                                                Class</Typography>
                                                            <Field placeholder="Select sub-class"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_sub_asset_class && values.sub_asset_class && lodash.find(edit_asset_sub_asset_class, function (sub_asset) {
                                                                       return sub_asset.value === values.sub_asset_class;
                                                                   })} options={edit_asset_sub_asset_class}
                                                                   name="sub_asset_class"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-row'>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Sector</Typography>
                                                            <Field placeholder="Select sector"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_sector && values.sector && lodash.find(edit_asset_sector, function (option) {
                                                                       return option.value === values.sector;
                                                                   })} options={edit_asset_sector} name="sector"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Industry
                                                                Group</Typography>
                                                            <Field placeholder="Select industry group"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_industry_group && values.industry_group && lodash.find(edit_asset_industry_group, function (option) {
                                                                       return option.value === values.industry_group;
                                                                   })} options={edit_asset_industry_group}
                                                                   name="industry_group"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-row'>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Investment
                                                                Type</Typography>
                                                            <Field placeholder="Select investment type"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_investment_type && values.investment_type && lodash.find(edit_asset_investment_type, function (option) {
                                                                       return option.value === values.investment_type;
                                                                   })} options={edit_asset_investment_type}
                                                                   name="investment_type"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Investment
                                                                Position</Typography>
                                                            <Field placeholder="Select investment position"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput}
                                                                   isDisable={values.investment_and_non_investment == 'non-investment' ? true : false}
                                                                   isFormik={true}
                                                                   selectedValue={edit_asset_investment_position && values.investment_position && lodash.find(edit_asset_investment_position, function (option) {
                                                                       return option.value === values.investment_position;
                                                                   })} options={edit_asset_investment_position}
                                                                   name="investment_position"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-row'>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Region</Typography>
                                                            <Field placeholder="Select region"
                                                                   className="selector-input"
                                                                   component={SimpleSelectInput} isFormik={true}
                                                                   selectedValue={edit_asset_region && values.region && lodash.find(edit_asset_region, function (option) {
                                                                       return option.value === values.region;
                                                                   })} options={edit_asset_region} name="region"/>
                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">State</Typography>
                                                            <Field placeholder="Select State" className="selector-input"
                                                                   component={SimpleSelectInput} isFormik={true}
                                                                   selectedValue={edit_asset_state && values.state && lodash.find(edit_asset_state, function (option) {
                                                                       return option.value === values.state;
                                                                   })} options={edit_asset_state} name="state"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='input-row'>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Entry Date</Typography>
                                                            <div className="date-picker-container">
                                                                <SimpleDatePicker
                                                                    organizationId={getOrganizationIdOFLoggedUser(user)}
                                                                    assetId={edit_asset_id}
                                                                    selectedDate={values.entry_date}  // Use Formik's value
                                                                    name="entry_date"                 // Pass the name for Formik
                                                                    setFieldValue={setFieldValue}     // Pass setFieldValue to update Formik's state
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="input-field-view">
                                                        <div className="input-with-label">
                                                            <Typography className="input-label">Exit Date</Typography>
                                                            <div className="date-picker-container">
                                                                <SimpleDatePicker
                                                                    organizationId={getOrganizationIdOFLoggedUser(user)}
                                                                    assetId={edit_asset_id}
                                                                    selectedDate={values.exit_date}  // Use Formik's value
                                                                    name="exit_date"                 // Pass the name for Formik
                                                                    setFieldValue={setFieldValue}     // Pass setFieldValue to update Formik's state
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="add-asset-modal-footer">
                                                    <div className="add-asset-modal-footer-content">
                                                        <div className="add-asset-modal-footer-buttons">
                                                            <button type="button" onClick={closeEditAssetModal}
                                                                    className="cancel-asset-button">Cancel
                                                            </button>
                                                            <button type="submit"
                                                                    className={"disable-add-asset-button " + `${formReadyToSubmit(values) ? 'enable-add-asset-button' : ''}`}>Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </Form>
                                    </>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Modal>
    )
}

export default EditAssetModal