import SideBarManagementsIcon from "../../components/icons/SideBarManagementsIcon";
import SideBarDashboardSubMenuIcon from "../../components/icons/SideBarDashboardSubMenuIcon";
import PortfolioOverview from "../../pages/user/dashboards/portfolio/PortfolioOverview.jsx";
import AssetSummaryPage from "../../pages/user/dashboards/assets/AssetSummaryPage";
import EventsDatabase from "../../pages/user/eventsDatabase/EventsDatabase";
import EventsDataBaseIcon from "../../components/icons/EventsDataBaseIcon";
import DashboardsIcon from "../../components/icons/DashboardsIcon";

import Organization from "../../pages/admin/management/organization/Organization";
import User from "../../pages/admin/management/user/User";

const adminRoutes = [
    {
        path: "/management",
        state: "management",
        parent: true,
        single: false,
        sidebarProps: {
          displayText: "Admin",
          icon: <SideBarManagementsIcon />
        },
        child: [
            {
                path: "/organization",
                element: <Organization />,
                state: "management.organization",
                parent: false,
                sidebarProps: {
                    displayText: "Organizations",
                    icon: <SideBarDashboardSubMenuIcon />
                },
            },
            {
                path: "/user",
                element: <User />,
                state: "management.user",
                parent: false,
                sidebarProps: {
                    displayText: "Users",
                    icon: <SideBarDashboardSubMenuIcon />
                }
            }
        ],
    },
    // {
    //     path: "/dashboards",
    //     state: "dashboards",
    //     parent: true,
    //     single: false,
    //     sidebarProps: {
    //       displayText: "Dashboards",
    //       icon: <DashboardsIcon />
    //     },
    //     child: [
    //         {
    //             path: "/portfolio-overview",
    //             element: <PortfolioOverview />,
    //             state: "dashboards.portfolio",
    //             parent: false,
    //             sidebarProps: {
    //                 displayText: "Portfolio Overview",
    //                 icon: <SideBarDashboardSubMenuIcon />
    //             },
    //         },
    //         {
    //             path: "/asset-summary",
    //             element: <AssetSummaryPage />,
    //             state: "dashboards.asset",
    //             parent: false,
    //             sidebarProps: {
    //                 displayText: "Asset Summary",
    //                 icon: <SideBarDashboardSubMenuIcon />
    //             }
    //         }
    //     ]
    // },
    {
        path: "/portfolio-overview",
        element: <PortfolioOverview />,
        state: "portfolio",
        parent: true,
        single: true,
        sidebarProps: {
            displayText: "Portfolio Overview",
            icon: <DashboardsIcon />
        },
    },

    {
        path: "/asset-summary",
        element: <AssetSummaryPage />,
        state: "asset",
        parent: true,
        single: true,
        sidebarProps: {
            displayText: "Asset Summary",
            icon: <DashboardsIcon />
        }
    },
    {
        path: "/events-database",
        element: <EventsDatabase />,
        state: "events-database",
        parent: true,
        single: true,
        sidebarProps: {
          displayText: "Events Database",
          icon: <EventsDataBaseIcon />
        }
    }
];

export default adminRoutes;