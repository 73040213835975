import React from 'react'

function EditInputIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 16.6662H18M3 16.6662H4.39545C4.8031 16.6662 5.00693 16.6662 5.19874 16.6202C5.3688 16.5793 5.53138 16.512 5.6805 16.4206C5.84869 16.3175 5.99282 16.1734 6.28107 15.8852L16.75 5.4162C17.4404 4.72585 17.4404 3.60656 16.75 2.9162C16.0597 2.22585 14.9404 2.22585 14.25 2.9162L3.78105 13.3852C3.4928 13.6734 3.34867 13.8175 3.2456 13.9857C3.15422 14.1348 3.08688 14.2974 3.04605 14.4675C3 14.6593 3 14.8631 3 15.2708V16.6662Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default EditInputIcon