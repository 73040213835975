import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SearchBar from '../../../../components/commonComponents/SearchBar';
import UserFilterIcon from '../../../../components/icons/FilterButtonIcon';
import AddAssetIcon from '../../../../components/icons/AddAssetIcon';
import {isAddAssetAllowedToLoggedUser, getOrganizationIdOFLoggedUser} from '../../../../util/commonFunctions';

import {
    showAddAssetModal,
    getAddAssetInitialData,
    setAssetSummaryOverviewDateFilter
} from '../../../../redux/slices/assets/assetSummarySlice';
import lodash from "lodash";
import SimpleSelectInput from "../../../../components/commonComponents/SimpleSelectInput";

export default function AssetSummaryHeaderContent() {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)

    const assetIrrOverTimeGraphDateFilterOptions = useSelector(state => state.assetSummary.assetIrrOverTimeGraphDateFilterOptions)
    const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)


    const openAddAssetModal = () => {
        dispatch(getAddAssetInitialData({organization: getOrganizationIdOFLoggedUser(user)}))
        dispatch(showAddAssetModal())
    }

    const handleChangeDate = (selectedValue) => {
        dispatch(setAssetSummaryOverviewDateFilter({assetSummaryOverviewDateFilterValue: selectedValue.value}))
    }

    return (
        <div className="asset-summary-header-content">
            <span className="manage-asset-summary-text">Asset Summary</span>

            <div className='floating-selector-view'>
                <SimpleSelectInput showLabel="true" placeholder="Date" className="portfolio-filter-category select-filter"
                                   selectedBackgroundColor="black" selectedColor="white"
                                   selectedValue={lodash.find(assetIrrOverTimeGraphDateFilterOptions, function (date) {
                                       return date.value === assetSummaryOverviewDateFilterValue
                                   })} handleOnChange={handleChangeDate} options={assetIrrOverTimeGraphDateFilterOptions} isFormik={false}
                                   name="portfolio_date"/>
            </div>
            <SearchBar/>
            <div className="asset-summary-table-filters">
                <button className="filters-button"><UserFilterIcon/>Filters</button>
                {isAddAssetAllowedToLoggedUser(user) &&
                    <button className="add-asset-summary-button" onClick={openAddAssetModal}><AddAssetIcon/>Add Asset
                    </button>}
            </div>
        </div>
    )
}