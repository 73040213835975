import React, { useState, useEffect, useRef, useCallback } from 'react';
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import TableActionButtons from '../../../../components/table/TableActionButtons';
import Loader from '../../../../components/commonComponents/Loader';
import { getOrganizationIdOFLoggedUser, isAddAssetAllowedToLoggedUser } from '../../../../util/commonFunctions';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetEventDetail, getEditEventInitialData, getAssetEvents, showEditEventModal, getEventsInitialData } from '../../../../redux/slices/assets/assetSummarySlice';
import { showConfirmationAlertModal } from '../../../../redux/slices/alertSlice';
import IconToolTip from "../../../../components/IconToolTip";

function AssetsummaryEvents(props) {

    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.user)
    const eventsSummaryTableColumns = useSelector(state => state.assetSummary.eventsSummaryTableColumns)
    const eventsSummaryData = useSelector(state => state.assetSummary.eventsSummaryData)

    const [assetsummaryEventsLoading, setAssetsummaryEventsLoading] = useState(true)

    useGetAssetsEvents(dispatch, props.asset_id, user, eventsSummaryTableColumns, setAssetsummaryEventsLoading)

    const getDisplayedValue = (value, name) => {
      switch (name) {
        case 'date':
        case 'type':
          return value ? value : '---';
        case 'total_amount':
          const formattedNumber = Math.abs(value);
          let formattedValue = value === 0 ? '---' : value < 0 ? '-$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$' + formattedNumber.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
          return formattedValue
        case 'share_price':
          return value ? '$' + value.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '---';
        default:
          return '---';
      }
    };

    const getHeaderWidth = (header) => {
      switch (header.col_key) {
        case 'date':
          return '17.5625rem';
        case 'type':
          return '15.25rem';
        case 'amount':
          return '15.125rem';
        case 'share_price':
          return '10.5625rem';
        default:
          return '';
      }
    };

    const showEditModal = useCallback((id, retry=0) => {

      let params = {
        asset_id: props.asset_id,
        organization: getOrganizationIdOFLoggedUser(user),
        event_id: id,
      }
      dispatch(getEditEventInitialData(params))
      .then(({payload}) => {
        if(payload && payload.status === 200) {
          dispatch(showEditEventModal({selectedAssetId: payload.data.selected_event_data.asset_id, editEventScreenName: 'asset_summary'}))
        }
        else if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
          dispatch(getNewAccessToken())
          .then((res) => {
            if(res.payload && res.payload.data && res.payload.data.success) {
              return showEditModal(id, retry + 1)
            }
          })
        }
      })
    }, [])

    const removeUser = useCallback((id) => {
      let event = lodash.find(eventsSummaryData, function(event) { return event.id === id })
      let alert_msg =  'Are you sure you want to delete event: ' + event.date + '?'
      if (event.is_creation_event) {
          alert_msg =  'Are you sure you want to delete this event ? Deleting this event will delete the associated asset and all its events'
      }
      let alert_params = {
        alertTitle: 'Delete Event',
        alertMessage: alert_msg,
        alertType: 'remove_event',
        screen: 'asset_summary',
        removing_id: id,
        organization: getOrganizationIdOFLoggedUser(user),
        asset_id: props.asset_id,
      }
  
      dispatch(showConfirmationAlertModal(alert_params))
    }, [eventsSummaryData, user, props.asset_id])

    return (
      assetsummaryEventsLoading ?
      <Loader height="40rem" />
      :
      <TableContainer className="events-summary-table" component={Paper}>
          <Table aria-label="simple table">
          <TableHead>
              <TableRow>
              { eventsSummaryTableColumns && eventsSummaryTableColumns.map((header, index) => (
                  <TableCell key={index} style={{ width: getHeaderWidth(header) }} className={ header.col_key === 'share_price' ? 'share-price-column' : ''} align="left">
                      { header.col_name }
                      {header.col_key === 'share_price' && (
                          <div>
                              <Tooltip title="Internal tracking price, not actual investment share price">
                              <InfoOutlinedIcon/>
                              </Tooltip>
                          </div>


                        )}

                  </TableCell>

              ))}
              <TableCell style={{ width: '10rem' }} align="left">Notes</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              { eventsSummaryData && eventsSummaryData.map((row, index) => (
              <TableRow
                  className='table-data-row'
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align="left" scope="row">{ getDisplayedValue(row.date, 'date') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.type, 'type') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.total_amount, 'total_amount') }</TableCell>
                  <TableCell align="left">{ getDisplayedValue(row.share_price, 'share_price') }</TableCell>
                  <TableCell align="left">{<TableActionButtons toolTipData={row.notes} disable={ false } isFileAllow={ true } viewable={ row.viewable } showToolTip={ true } isRemoveAllow={ isAddAssetAllowedToLoggedUser(user) } isEditAllow={ isAddAssetAllowedToLoggedUser(user) } isExportAllow={ false } remove={ () => removeUser(row.id)} showEditModal={ () => showEditModal(row.id) } />}</TableCell>
              </TableRow>
              ))}
          </TableBody>
          </Table>
      </TableContainer>
    );
}

export default AssetsummaryEvents

async function getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventsLoading, retry = 0) {
  let params = {
    organization: getOrganizationIdOFLoggedUser(user),
    asset_id: asset_id,
  }

  const { payload } = await dispatch(getAssetEvents(params))

  if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
    await dispatch(getNewAccessToken());

    return getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventsLoading, retry + 1);
  }
  else if(payload && payload.status === 200) {
    setAssetsummaryEventsLoading(false)
  }
  return payload
}

function useGetAssetsEvents(dispatch, asset_id, user, eventsSummaryTableColumns, setAssetsummaryEventsLoading) {
  const isInitialMount = useRef(true);
  useEffect(() => {
  // This block will execute when the component re-renders due to asset_id changes
  const fetchAssetsEvents = async () => {
    const usersData = await getAssetsEvents(dispatch, asset_id, user, setAssetsummaryEventsLoading);
  };

  setAssetsummaryEventsLoading(true)
  fetchAssetsEvents();
  }, [asset_id]);
}