import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../../../components/commonComponents/Loader';

import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getAssetIrrOverTimeValue } from '../../../../redux/slices/assets/assetSummarySlice';
import {cloneDeep} from "lodash";

function AssetIrrOverTimeGraph(props) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)

    const lineChartQuarters = useSelector(state => state.assetSummary.quarters)
    const lineChartSeries = useSelector(state => state.assetSummary.series)

    const graphCategories = useSelector(state => state.assetSummary.assetIrrOverTimeGraphCategories)
    const series = cloneDeep(useSelector(state => state.assetSummary.assetIrrOverTimeGraphSeries))
    const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)

    const [assetIrrOverTimeGraphLoading, setAssetIrrOverTimeGraphLoading] = useState(true)

    useGetAssetIrrOverTime(dispatch, props.asset_id, lineChartQuarters, lineChartSeries, assetSummaryOverviewDateFilterValue, user, setAssetIrrOverTimeGraphLoading)

    let graphConfig = {}
    
    if(graphCategories && series ) {
        let maxDataValue = Math.max(...series[0]['data']);
        if (maxDataValue % 5 !== 0) {
          maxDataValue = Math.ceil(maxDataValue / 5) * 5;
        }
        graphConfig = {
            series: series,
            options: {
                chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    show: false,
                }
                },
                dataLabels: {
                enabled: false
                },
                markers: {
                size: 0,
                },
                fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
                },
                yaxis: {
                title: {
                    text: 'Percentage',
                    offsetX: 0,
                },
                labels: {
                    formatter: function (value, index) {
                        if (value === 0) {
                        return '0';
                        } else {
                        return value.toLocaleString('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) + '%';
                        }
                    },
                    },
                tickAmount: 5,
                min: 0,
                max: maxDataValue,
                },
                xaxis: {
                title: {
                    text: 'Quarter'
                },
                categories: graphCategories,
                },
                tooltip: {
                shared: false,
                x: {
                    formatter: function (val) {
                    return (val).toFixed(1)
                    }
                },
                y: {
                    formatter: function (val) {
                    return (val/100).toLocaleString('en-US', {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    }
                }
                },
                legend: {
                position: 'top',
                horizontalAlign: 'right',
                }
            }
        }
    }

    return (
        <div className='graph-card'>
            <div className='content'>
                <div className='content-header'>
                    <div className='content'>
                        <div className='text-view'>
                            <span className='text'>IRR Over Time</span>
                        </div>
                    </div>
                </div>
                { assetIrrOverTimeGraphLoading ?
                    <Loader height="15rem" />
                    :
                    <div id="chart" className='investment-assets-value-by-category-view'>
                        { graphConfig && graphConfig.options && <ReactApexChart className='investment-assets-value-by-category-chart' options={graphConfig.options} series={graphConfig.series} type='area' height={400} /> }
                    </div>
                }
            </div>
        </div>
    )
}

function useGetAssetIrrOverTime(dispatch, asset_id, lineChartQuarters, lineChartSeries, assetSummaryOverviewDateFilterValue, user, setAssetIrrOverTimeGraphLoading) {
  const isInitialMount = useRef(true);

    useEffect(() => {
        setAssetIrrOverTimeGraphLoading(true)
        if(lineChartQuarters && lineChartQuarters.length > 0 && lineChartSeries && lineChartSeries.length > 0) {
            fetchData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetIrrOverTimeGraphLoading)
            isInitialMount.current = false;
        }
    }, [asset_id, lineChartQuarters, lineChartSeries, assetSummaryOverviewDateFilterValue]);

    async function fetchData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetIrrOverTimeGraphLoading, retry=0) {
        let params = {
            asset_id: asset_id,
            assetSummaryOverviewDateFilterValue: assetSummaryOverviewDateFilterValue,
        }

        const { payload } = await dispatch(getAssetIrrOverTimeValue(params))

        if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
            dispatch(getNewAccessToken());
            fetchData(dispatch, asset_id, user, assetSummaryOverviewDateFilterValue, setAssetIrrOverTimeGraphLoading, retry + 1);
        }
        else if(payload && payload.status === 200) {
            setAssetIrrOverTimeGraphLoading(false)
        }
    }
}

export default AssetIrrOverTimeGraph