import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelectInput from '../../../../components/commonComponents/SimpleSelectInput';
import lodash from 'lodash'

import { getOrganizationIdOFLoggedUser } from '../../../../util/commonFunctions';
import { getNewAccessToken } from '../../../../redux/slices/auth/authSlice';
import { getPortfolioInitialData, setFilterDateOfInvestmentAssetsValueByCategoryViewGraphType, setFilterCategoryOfInvestmentAssetsValueByCategoryViewGraphType, setFilterEntityOfInvestmentAssetsValueByCategoryViewGraphType } from '../../../../redux/slices/portfolio/portfolioSlice';
import {setAssetSummaryOverviewDateFilter} from "../../../../redux/slices/assets/assetSummarySlice";

function PortfolioFilters() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user)
    const dates = useSelector(state => state.portfolio.dates)
    const categories = useSelector(state => state.portfolio.categories)
    const entities = useSelector(state => state.portfolio.entities)
    const filterDate = useSelector(state => state.portfolio.filterDate)

    const filterCategory = useSelector(state => state.portfolio.filterCategory)
    const filterEntity = useSelector(state => state.portfolio.filterEntity)

    const assetSummaryOverviewDateFilterValue = useSelector(state => state.assetSummary.assetSummaryOverviewDateFilterValue)
    

    useEffect(() => {
        if (assetSummaryOverviewDateFilterValue) {
            dispatch(setFilterDateOfInvestmentAssetsValueByCategoryViewGraphType({ filterDate: assetSummaryOverviewDateFilterValue }))
        }
    }, [assetSummaryOverviewDateFilterValue, dispatch]);


    useGetPortfolioInitialData(dispatch, user);

    const handleChangeDate = (selectedOption) => {
        dispatch(setFilterDateOfInvestmentAssetsValueByCategoryViewGraphType({ filterDate: selectedOption.value }))
        // dispatch(setAssetSummaryOverviewDateFilter({assetSummaryOverviewDateFilterValue: selectedOption.value}))
    }

    const handleChangeCategory = (selectedOption) => {
        dispatch(setFilterCategoryOfInvestmentAssetsValueByCategoryViewGraphType({ filterCategory: selectedOption.value }))
    }

    const handleChangeEntity = (selectedOption) => {
        dispatch(setFilterEntityOfInvestmentAssetsValueByCategoryViewGraphType({ filterEntity: selectedOption.value }))
    }

    return (
        <>
            <SimpleSelectInput showLabel="true" placeholder="Date" className="portfolio-filter-date select-filter" selectedBackgroundColor="black" selectedColor="white" selectedValue={ lodash.find(dates, function(date) { return date.value === filterDate }) } handleOnChange={ handleChangeDate } options={ dates } isFormik={false} name="portfolio_date" />
            <SimpleSelectInput showLabel="true" placeholder="Category" className="portfolio-filter-category select-filter" selectedBackgroundColor="black" selectedColor="white" selectedValue={ lodash.find(categories, function(category) { return category.value === filterCategory }) } handleOnChange={ handleChangeCategory } options={ categories } isFormik={false} name="portfolio_category" />
            <SimpleSelectInput showLabel="true" placeholder="Entity" className="portfolio-filter-entity select-filter" selectedBackgroundColor="black" selectedColor="white" selectedValue={ lodash.find(entities, function(entity) { return entity.value === filterEntity }) } handleOnChange={ handleChangeEntity } options={ entities } isFormik={false} name="portfolio_entity" />
        </>
    )
}

async function getPortfolio(dispatch, user, retry = 0) {
    const { payload } = await dispatch(getPortfolioInitialData({ organization: getOrganizationIdOFLoggedUser(user) }))
  
    if(payload && payload.response && payload.response.status === 401 && retry < process.env.REACT_APP_MAX_RETRY_FOR_REFRESH_TOKEN) {
      await dispatch(getNewAccessToken());
  
      return getPortfolio(dispatch, user, retry + 1);
    }
  
    return payload
}
  
function useGetPortfolioInitialData(dispatch, user) {
    const isMountRef = useRef(true);
    useEffect(() => {
      if(isMountRef.current) {
        const fetchInitialData = async () => {
          const usersData = await getPortfolio(dispatch, user);
        };
    
        fetchInitialData();
        isMountRef.current = false;
      }
    }, []);
}

export default PortfolioFilters