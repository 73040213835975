import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssetSummary from './AssetSummary'
import AssetSummaryDetail from './AssetSummaryDetail';
import {setAssetId, setAssetInvestmentType} from "../../../../redux/slices/assets/assetSummarySlice";

function AssetSummaryPage(props) {

    const dispatch = useDispatch()

    // const [asset_id, setSelectedAssetIdToViewAsset] = useState('')
    // const [asset_investment_type, setSelectedAssetInvestmentType] = useState('')


    const asset_id = useSelector(state => state.assetSummary.asset_id);
    const asset_investment_type = useSelector(state => state.assetSummary.asset_investment_type);


    const setSelectedAssetIdToViewAssetDetail = (asset_id) => {
        dispatch(setAssetId(asset_id));
    }

    const setSelectedAssetInvestmentTypeDetail = (asset_investment_type) => {
        dispatch(setAssetInvestmentType(asset_investment_type));
    }

    return (
        <>
            { asset_id ?
                <AssetSummaryDetail asset_id={asset_id}
                                    setSelectedAssetIdToViewAssetDetail={ setSelectedAssetIdToViewAssetDetail }
                                    setSelectedAssetInvestmentTypeDetail={ setSelectedAssetInvestmentTypeDetail }
                                    investment_type={ asset_investment_type } />
                :
                <AssetSummary setSelectedAssetIdToViewAssetDetail={ setSelectedAssetIdToViewAssetDetail }
                 setSelectedAssetInvestmentTypeDetail={ setSelectedAssetInvestmentTypeDetail }/>
            }
        </>
    )
}

export default AssetSummaryPage;