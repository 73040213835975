import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'
import lodash  from 'lodash'

export const getOrganizationEvents = createAsyncThunk('EVENTS/getOrganizationEvents', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/events-database/'+ values.organization + '/', {
            params: { from_date: values.from_date, end_date: values.end_date },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getDataBaseEventInitialData = createAsyncThunk('EVENT/getDataBaseEventInitialData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/event-intial-data/'+ values.organization + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getDataBaseEventInitialDataForSelectedAsset = createAsyncThunk('ASSETEVENT/getDataBaseEventInitialDataForSelectedAsset', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/event-intial-data/'+ values.organization + '/' + values.asset_id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createNewDataBaseEvent = createAsyncThunk('ASSET/createNewDataBaseEvent', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.post('/events/'+ params.organization + '/' + params.asset_id + '/', params.values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const eventDataBaseSlice = createSlice({

    name: 'eventDataBase',
    initialState: {
        organizationEvents: [],
        editOrganization: '',
        events_database_table_columns: '',
        addEventDataBaseModalShown: false,
        assets: [],
        eventTypes: [],
        selectedAssetEntities: [],
        sharePrice: '',
        lastValuationAmount: '',
        lastValuationTotalShares: '',
        eventDataBaseDataBeforeNext: {},
        allEntities: [],
        newEntitiesListMaxCount: 0,
        newEventEntitiesListMaxCount: 0,
        progress: 1,
        currentRequestId: '',
        error: "",
    },
    reducers: {
        showAddEventdataBaseModal: state => {
            state.addEventDataBaseModalShown = true
            state.selectedAssetEntities = []
            state.eventDataBaseDataBeforeNext = {}
            state.progress = 1

        },
        hideAddEventdataBaseModal: state => {
            state.addEventDataBaseModalShown = false
        },
        setDataBaseEventValues: (state, {payload})=> {
            state.eventDataBaseDataBeforeNext = payload
        },
        setNewEntitiesMaxCount: (state, {payload}) => {
            state.newEntitiesListMaxCount = payload
        },
        setNewEventEntitiesMaxCount: (state, {payload}) => {
            state.newEventEntitiesListMaxCount = payload
        },
        setNextAndBackToForm: (state, {payload}) => {
            state.progress = payload
        },
    },
    extraReducers(builder) {
        //getOrganizationEvents response handler
        builder
        .addCase(getOrganizationEvents.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.organizationEvents = payload.data.data
                    state.events_database_table_columns = payload.data.table_cols
                }
            }
        })
        .addCase(getOrganizationEvents.pending, (state, { meta }) => {
            state.currentRequestId = meta
        })
        .addCase(getOrganizationEvents.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getDataBaseEventInitialData response handler
        .addCase(getDataBaseEventInitialData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.assets = payload.data.asset.map((option, index) => ({ value: option.id, label: option.name, investment: option.investment}))
                    state.eventTypes = payload.data.event_types.map((option, index) => ({ value: index, label: option}))
                }
            }
        })
        .addCase(getDataBaseEventInitialData.pending, (state, { meta }) => {
            state.currentRequestId = meta
        })
        .addCase(getDataBaseEventInitialData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getDataBaseEventInitialDataForSelectedAsset response handler
        .addCase(getDataBaseEventInitialDataForSelectedAsset.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.selectedAssetEntities = payload.data.entities.map((option, index) => ({ id: index, existed_entity: true, entity_name: option.entity_name, amount: option.amount, percentage: option.percentage }))
                    state.sharePrice = payload.data.share_price
                    state.lastValuationAmount = payload.data.last_valuation_amount
                    state.lastValuationTotalShares = payload.data.last_valuation_total_shares
                    state.newEventEntitiesListMaxCount = payload.data.entities.length
                    state.allEntities = payload.data.all_entities.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                }
            }
        })
        .addCase(getDataBaseEventInitialDataForSelectedAsset.pending, (state, { meta }) => {
            state.currentRequestId = meta
        })
        .addCase(getDataBaseEventInitialDataForSelectedAsset.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //createNewDataBaseEvent response handler
        .addCase(createNewDataBaseEvent.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 201) {
                }
            }
        })
        .addCase(createNewDataBaseEvent.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(createNewDataBaseEvent.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
    }
})

export const { showAddEventdataBaseModal, hideAddEventdataBaseModal, showEditOrganizationModal, hideEditOrganizationModal, setDataBaseEventValues, setNewEntitiesMaxCount, setNewEventEntitiesMaxCount, setNextAndBackToForm, setLastValuationAmount } = eventDataBaseSlice.actions
export default eventDataBaseSlice.reducer