import { ListItemButton, ListItemIcon, Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import colorConfigs from "../configs/sidebar/colorConfigs";
import {useDispatch} from "react-redux";
import {resetAssetDetails} from "../../redux/slices/assets/assetSummarySlice";

export default function SidebarItem({ item, single }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
      dispatch(resetAssetDetails());
      navigate(item.path);
  };

  return (
    item.sidebarProps && item.path ? (
      <Box className={single ? 'single-item-without-collapse' : 'item-with-collapse'}>
        <ListItemButton
          onClick={handleClick}
          selected={item.path === location.pathname}
          sx={{
            fontFamily: 'Inter',
            borderRadius: '6.25rem',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.5rem',
            display: 'flex',
            padding: '0.5rem 0.75rem 0.5rem 1rem',
            alignItems: 'center',
            gap: '0.75rem',
            flex: '1 0 0',
          }}
        >
          <ListItemIcon
            sx={{
              color: colorConfigs.sidebar.color,
              minWidth: 0,
            }}
          >
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          {item.sidebarProps.displayText}
        </ListItemButton>
      </Box>
    ) : null
  );
}
