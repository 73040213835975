import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import Http from '../../../util/Http'
import lodash from 'lodash'
import { getLoggedUserAccessToken } from '../../../util/commonFunctions'

export const getAssetSummary = createAsyncThunk('ASSET/getAssetSummary', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/asset-summary/'+ values.organization + '/', {
            params: {
                search_filter: values.assetSummarySearchFilterValue,
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAddAssetInitialData = createAsyncThunk('ASSET/getAddAssetInitialData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/asset-initial-data/'+ values.organization + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createNewAsset = createAsyncThunk('ASSET/createNewAsset', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.post('/asset-summary/'+ values.organization + '/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateAssetDetails = createAsyncThunk('ASSET/updateAssetDetails', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.put('/update-selected-asset/'+ values.organization + '/' + values.id + '/', values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateAsset = createAsyncThunk('ASSET/updateAsset', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.put('/asset-summary/'+ values.organization + '/' + values.id + '/', values.status, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetsOverViewData = createAsyncThunk('ASSET/getAssetsOverViewData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/get-overview/'+ values.organization + '/' + values.asset_id + '/', {
            params: {
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetDetails = createAsyncThunk('ASSET/getAssetDetails', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/get-asset-details/'+ values.organization + '/' + values.asset_id + '/', {
            params: {
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getCapitalSummary = createAsyncThunk('ASSET/getCapitalSummary', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/get-capital-summary/'+ values.organization + '/' + values.asset_id + '/', {
            params: {
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetEvents = createAsyncThunk('ASSET/getAssetEvents', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/events/'+ values.organization + '/' + values.asset_id + '/', {
            params: { from_date: values.from_date, end_date: values.end_date },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetEventSplits = createAsyncThunk('ASSET/getAssetEventSplits', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/event-splits/'+ values.organization + '/' + values.asset_id + '/', {
            params: { from_date: values.from_date, end_date: values.end_date, entity: values.entity },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getEventsInitialData = createAsyncThunk('ASSET/getEventsInitialData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/event-intial-data/'+ values.organization + '/' + values.asset_id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getEditEventInitialData = createAsyncThunk('ASSET/getEditEventInitialData', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/events-details/'+ values.organization + '/' + values.event_id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateEvent = createAsyncThunk('ASSET/updateEvent', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.put('/events-details/'+ params.organization + '/' + params.asset_id + '/' + params.event_id + '/', params.values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const createNewAssetEvent = createAsyncThunk('ASSET/createNewAssetEvent', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.post('/events/'+ params.organization + '/' + params.asset_id + '/', params.values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetEventDetail = createAsyncThunk('ASSET/getAssetEventDetail', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.get('/events-details/'+ params.organization + '/' + params.event_id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const updateAssetEvent = createAsyncThunk('ASSET/updateAssetEvent', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.put('/events-details/'+ params.organization + '/' + params.asset_id + '/' + params.event_id + '/', params.values, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteAsset = createAsyncThunk('ASSET/deleteAsset', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.delete('/asset-summary/' + params.organization + '/' + params.id + '/', {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})


export const deleteAssetEvent = createAsyncThunk('ASSET/deleteAssetEvent', async ( params, { rejectWithValue }) => {
    try {
        const response = await Http.delete('/events-details/' + params.organization + '/' + params.asset_id + '/' + params.id + '/' , {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getInvestmentValueOverTime = createAsyncThunk('EVENTS/getInvestmentValueOverTime', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/investment-value-over-time/'+ values.organization + '/' + values.asset_id + '/', {
            params: {
                from_date: values.from_date, end_date: values.end_date,
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const getAssetIrrOverTimeValue = createAsyncThunk('EVENTS/getAssetIrrOverTimeValue', async ( values, { rejectWithValue }) => {
    try {
        const response = await Http.get('/asset-irr-overtime-graph/' + values.asset_id + '/', {
            params: {
                quarter_dropdown: values.assetSummaryOverviewDateFilterValue
            },
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getLoggedUserAccessToken()}`,
            },
        })
    return response
    } catch (err) {
        return rejectWithValue(err)
    }
})

export const assetSummarySlice = createSlice({

    name: 'assetSummary',
    initialState: {
        asset_id: '',
        asset_investment_type: '',
        assets: [],
        assets_summary_table_columns: [],
        summary_data: [],
        assetOptions: [],
        eventTypes: [],
        sponsor_list: [],
        asset_class_list: [],
        sub_asset_class: [],
        sector: [],
        industry_group: [],
        investment_type: [],
        investment_position: [],
        region: [],
        state: [],
        entity: [],

        edit_asset_id: [],
        edit_asset_sponsor_list: [],
        edit_asset_asset_class_list: [],
        edit_asset_sub_asset_class: [],
        edit_asset_sector: [],
        edit_asset_industry_group: [],
        edit_asset_investment_type: [],
        edit_asset_investment_position: [],
        edit_asset_region: [],
        edit_asset_state: [],
    
        newEntitiesListMaxCount: 0,
        newEventEntitiesListMaxCount: 0,
        addAssetModalShown: false,
        editAssetModalShown: false,
        assetOverView: {},
        assetsWithDetails: {},
        capitalSummaryData: [],
        capitalSummaryTableColumns: [],
        eventsSummaryData: [],
        eventsSummaryTableColumns: [],
        addEventModalShown: false,
        addEventSelectedAssetId: '',
        editEventModalShown: false,
        editAssetEventData: {},
        assetsSelectorOptions: [],
        eventEntities: [],
        sharePrice: '',
        lastValuationAmount: '',
        lastValuationTotalShares: '',
        quarters: [],
        series: [],
        allEntities: [],
        editEventScreenName: '',
        assetIrrOverTimeGraphCategories: '',
        assetIrrOverTimeGraphSeries: '',
        assetIrrOverTimeGraphDateFilterOptions: '',
        assetSummaryOverviewDateFilterValue: '',
        assetSummarySearchFilterValue: '',
        eventSplitsData: [],
        eventSplitsEntities: [],
        eventSplitsTableColumns: [],
        assetSummaryEventSplitsEntityFilter: '',
        assetSummaryEventSplitsStartDateFilter: '',
        assetSummaryEventSplitsEndDateFilter: '',
        currentRequestId: '',
        error: "",
    },
    reducers: {
        setAssetId: (state, action) => {
          state.asset_id = action.payload;
        },
        setAssetInvestmentType: (state, action) => {
          state.asset_investment_type = action.payload;
        },
        resetAssetDetails: (state) => {
          state.asset_id = '';
          state.asset_investment_type = '';
        },
        showAddAssetModal: state => {
            state.addAssetModalShown = true
        },
        hideAddAssetModal: state => {
            state.addAssetModalShown = false
        },
        showEditAssetModal: state => {
            state.editAssetModalShown = true
        },
        hideEditAssetModal: state => {
            state.editAssetModalShown = false
        },
        setNewEntitiesMaxCount: (state, {payload}) => {
            state.newEntitiesListMaxCount = payload
        },
        setNewEventEntitiesMaxCount: (state, {payload}) => {
            state.newEventEntitiesListMaxCount = payload
        },
        showAddEventModal: (state , { payload }) => {
            state.addEventModalShown = true
            state.addEventSelectedAssetId = payload.selectedAssetId
        },
        hideAddEventModal: state => {
            state.addEventModalShown = false
            state.addEventSelectedAssetId = ''
        },
        showEditEventModal: (state , { payload }) => {
            state.editEventModalShown = true
            state.addEventSelectedAssetId = payload.selectedAssetId
            state.editEventScreenName = payload.editEventScreenName
        },
        hideEditEventModal: state => {
            state.editEventModalShown = false
            state.addEventSelectedAssetId = ''
            state.editEventScreenName = ''
        },
        setAssetSummaryOverviewDateFilter: (state , { payload }) => {
            state.assetSummaryOverviewDateFilterValue = payload.assetSummaryOverviewDateFilterValue
        },
        setAssetSummarySearchFilter: (state , { payload }) => {
            state.assetSummarySearchFilterValue = payload
        },
        setAssetSummaryEventSplitsDateFilter: (state , { payload }) => {
            state.assetSummaryEventSplitsStartDateFilter = payload.assetSummaryEventSplitsStartDateFilter
            state.assetSummaryEventSplitsEndDateFilter = payload.assetSummaryEventSplitsEndDateFilter
        },
        setAssetSummaryEventSplitsEntityFilter: (state , { payload }) => {
            state.assetSummaryEventSplitsEntityFilter = payload.assetSummaryEventSplitsEntityFilter
        }
    },
    extraReducers(builder) {
        //getAssetSummary response handler
        builder
          .addCase(getAssetSummary.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.assets = payload.data.data
                    state.assets_summary_table_columns = payload.data.table_cols
                    state.summary_data = payload.data.summary_data
                    state.assetOptions = payload.data.data.map(option => ({ value: option.id, label: option.name}))
                    state.assetIrrOverTimeGraphDateFilterOptions = Object.values(payload.data.date_range).map(option => ({ value: option, label: option}))
                }
            }
          })
          .addCase(getAssetSummary.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getAssetSummary.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //login response handler
        .addCase(getAddAssetInitialData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.sponsor_list = payload.data.sponsor.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                    state.asset_class_list = payload.data.asset_class.map((option, index) => ({ value: index + 1, label: option }))
                    state.sub_asset_class = payload.data.sub_asset_class.map((option, index) => ({ value: index + 1, label: option }))
                    state.sector = payload.data.sector.map((option, index) => ({ value: index + 1, label: option }))
                    state.industry_group = payload.data.industry_group.map((option, index) => ({ value: index + 1, label: option }))
                    state.investment_type = payload.data.investment_type.map((option, index) => ({ value: index + 1, label: option }))
                    state.investment_position = payload.data.investment_position.map((option, index) => ({ value: index + 1, label: option }))
                    state.region = payload.data.region.map((option, index) => ({ value: index + 1, label: option }))
                    state.state = payload.data.state.map((option, index) => ({ value: index + 1, label: option }))
                    state.entity = payload.data.entity.map((option, index) => ({ value: option.id, label: option.name }))
                }
            }
          })
          .addCase(getAddAssetInitialData.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(getAddAssetInitialData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //createNewAsset response handler
        .addCase(createNewAsset.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
          })
          .addCase(createNewAsset.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(createNewAsset.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //updateAssetDetails response handler
        .addCase(updateAssetDetails.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
          })
          .addCase(updateAssetDetails.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updateAssetDetails.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //updateAsset response handler
        .addCase(updateAsset.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
          })
          .addCase(updateAsset.pending, (state, { meta }) => {
            state.currentRequestId = meta

          })
          .addCase(updateAsset.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAssetsOverViewData response handler
        .addCase(getAssetsOverViewData.fulfilled, (state, { meta, payload }) => {
            // if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.assetOverView = payload.data.overview
                    state.assetsSelectorOptions = payload.data.assets.map((option, index) => ({ value: option.id, label: option.name, investment: option.investment}))
                    state.assetIrrOverTimeGraphDateFilterOptions = Object.values(payload.data.date_range).map(option => ({ value: option, label: option}))
                    
                }
            // }
        })
        .addCase(getAssetsOverViewData.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetsOverViewData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAssetDetails response handler
        .addCase(getAssetDetails.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.assetsWithDetails = payload.data


                    state.edit_asset_sponsor_list = payload.data.edit_data.sponsor.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                    state.edit_asset_id = payload.data.id
                    state.edit_asset_asset_class_list = payload.data.edit_data.asset_class.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_sub_asset_class = payload.data.edit_data.sub_asset_class.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_sector = payload.data.edit_data.sector.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_industry_group = payload.data.edit_data.industry_group.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_investment_type = payload.data.edit_data.investment_type.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_investment_position = payload.data.edit_data.investment_position.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_region = payload.data.edit_data.region.map((option, index) => ({ value: index + 1, label: option }))
                    state.edit_asset_state = payload.data.edit_data.state.map((option, index) => ({ value: index + 1, label: option }))

                }
            }
        })
        .addCase(getAssetDetails.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetDetails.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getCapitalSummary response handler
        .addCase(getCapitalSummary.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.capitalSummaryData = payload.data.data
                    state.capitalSummaryTableColumns = payload.data.table_cols
                }
            }
        })
        .addCase(getCapitalSummary.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getCapitalSummary.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAssetEvents response handler
        .addCase(getAssetEvents.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.eventsSummaryData = payload.data.data
                    state.eventsSummaryTableColumns = payload.data.table_cols
                }
            }
        })
        .addCase(getAssetEvents.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetEvents.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAssetEventSplits response handler
        .addCase(getAssetEventSplits.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    let newObject = {id: '', name: 'All', organization: 9};
                    payload.data.entities.unshift(newObject);
                    state.eventSplitsData = payload.data.data
                    state.eventSplitsEntities = payload.data.entities.map((option, index) => ({ label: option.name, value: option.id, organization: option.organization }))
                    state.eventSplitsTableColumns = payload.data.table_cols
                }
            }
        })
        .addCase(getAssetEventSplits.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetEventSplits.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getEventsInitialData response handler
        .addCase(getEventsInitialData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.eventTypes = payload.data.event_types.map((option, index) => ({ value: index, label: option}))
                    state.eventEntities = payload.data.entities.map((option, index) => ({ id: index, existed_entity: true, entity_name: option.entity_name, amount: option.amount, percentage: option.percentage }))
                    state.sharePrice = payload.data.share_price
                    state.lastValuationAmount = payload.data.last_valuation_amount
                    state.lastValuationTotalShares = payload.data.last_valuation_total_shares
                    state.newEventEntitiesListMaxCount = payload.data.entities.length
                    state.allEntities = payload.data.all_entities.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                }
            }
        })
        .addCase(getEventsInitialData.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getEventsInitialData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getEditEventInitialData response handler
        .addCase(getEditEventInitialData.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.eventTypes = payload.data.event_types.map((option, index) => ({ value: index, label: option}))
                    state.eventEntities = payload.data.entities.map((option, index) => ({ id: index, existed_entity: true, entity_name: option.entity_name, amount: option.amount, percentage: option.percentage }))
                    state.sharePrice = payload.data.share_price
                    state.newEventEntitiesListMaxCount = payload.data.entities.length
                    state.allEntities = payload.data.all_entities.map(option => ({ value: option.id, label: option.name, organization: option.organization }))
                    state.assetOptions = payload.data.asset.map(option => ({ value: option.id, label: option.name}))
                    state.editAssetEventData = payload.data.selected_event_data
                }
            }
        })
        .addCase(getEditEventInitialData.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getEditEventInitialData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //updateEvent response handler
        .addCase(updateEvent.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                }
            }
        })
        .addCase(updateEvent.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(updateEvent.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //createNewAssetEvent response handler
        .addCase(createNewAssetEvent.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 201) {
                }
            }
        })
        .addCase(createNewAssetEvent.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(createNewAssetEvent.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getAssetEventDetail response handler
        .addCase(getAssetEventDetail.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    let data = {
                        id: payload.data.id,
                        asset: payload.data.asset_id,
                        date: payload.data.date,
                        type: payload.data.type,
                        notes: payload.data.notes,
                        total_amount: payload.data.total_amount,
                    }
                    state.editAssetEventData = data
                }
            }
        })
        .addCase(getAssetEventDetail.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetEventDetail.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //updateAssetEvent response handler
        .addCase(updateAssetEvent.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 201) {
                }
            }
        })
        .addCase(updateAssetEvent.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(updateAssetEvent.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //deleteAssetEvent response handler
        .addCase(deleteAssetEvent.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
        })
        .addCase(deleteAssetEvent.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(deleteAssetEvent.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //deleteAsset response handler
        .addCase(deleteAsset.fulfilled, (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
            }
        })
        .addCase(deleteAsset.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(deleteAsset.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
        //getInvestmentValueOverTime response handler
        .addCase(getInvestmentValueOverTime.fulfilled, (state, { meta, payload }) => {
            // if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                   state.quarters = payload.data.quarters
                   state.series = payload.data.series
                }
            // }
        })
        .addCase(getInvestmentValueOverTime.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getInvestmentValueOverTime.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })

        //getAssetIrrOverTimeValue response handler
        .addCase(getAssetIrrOverTimeValue.fulfilled, (state, { meta, payload }) => {
            // if (meta.requestId === state.currentRequestId.requestId) {
                if(payload.status === 200) {
                    state.assetIrrOverTimeGraphCategories = payload.data.categories
                    state.assetIrrOverTimeGraphSeries = payload.data.series
                }
            // }
        })
        .addCase(getAssetIrrOverTimeValue.pending, (state, { meta }) => {
            state.currentRequestId = meta

        })
        .addCase(getAssetIrrOverTimeValue.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta
                state.error = error
            }
        })
    }
})

export const { setAssetId, setAssetInvestmentType, resetAssetDetails, showAddAssetModal, hideAddAssetModal, showEditAssetModal, hideEditAssetModal, setNewEntitiesMaxCount, showAddEventModal, hideAddEventModal, showEditEventModal, hideEditEventModal, setNewEventEntitiesMaxCount, setAssetSummaryOverviewDateFilter, setAssetSummarySearchFilter, setAssetSummaryEventSplitsEntityFilter, setAssetSummaryEventSplitsDateFilter } = assetSummarySlice.actions
export default assetSummarySlice.reducer