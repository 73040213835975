import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from "date-fns";

function SimpleDatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate ? new Date(props.selectedDate) : '');

  useEffect(() => {
    if (props.selectedDate) {
      setSelectedDate(new Date(props.selectedDate));
    }
  }, [props.selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);

    // Pass the selected date back to Formik
    if (props.setFieldValue && props.name) {
      props.setFieldValue(props.name, format(newDate, 'yyyy-MM-dd'));  // Update Formik field with formatted date
    }

    if (props.onDateChange) {
      props.onDateChange(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            // sx={{
            //   '& .MuiInputBase-input': {
            //     maxWidth: '107px',
            //   },
            //   '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
            //       width: '340px',
            //       maxHeight: '38px',
            //       justifyContent: 'space-between'
            //   },
            // }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default SimpleDatePicker;
